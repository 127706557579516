import React from 'react';
import Layout from './../components/Layout';


const NotFound = () => {
    return(
        <Layout>
            <div className="main-wrapper">
                    <h2 className="text-align-center">Ups, podana strona nie istnieje.</h2>
            </div>
        </Layout>
    )
}

export default NotFound;